





























































import './scss/BaseActionsGrouped.scss';
import './scss/BaseActionsGroupedAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAction } from '@/interfaces/IAction';

@Component({
    name: 'BaseActions',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class BaseActions extends Vue {
    @Prop({}) actionLists!: IAction[][];
    @Prop({ default: true }) closeOnChoose!: boolean;
    @Prop( {}) buttonText!: string;
    @Prop({ default: false }) buttonBorder!: boolean;

    emitAction(action: IAction) {
        this.$emit('action', action);
    }

    actionListToggler = false;

    toggleActionList() {
        this.actionListToggler = !this.actionListToggler;
        setTimeout(() => {
            if (this.actionListToggler) {
                document.addEventListener('click', this.documentClickHandler);
            }
        }, 0);
    }

    documentClickHandler(): void {
        this.actionListToggler = false;
        document.removeEventListener('click', this.documentClickHandler);
    }

    closeOptions(): void {
        this.actionListToggler = false;
        document.removeEventListener('click', this.documentClickHandler);
    }

    chooseAction(action: IAction) {
        this.emitAction(action);
        if (this.closeOnChoose) {
            this.closeOptions();
        }
    }
}
